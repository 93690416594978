import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

class MidtermPage extends React.Component {
    render() {
        const siteTitle = "Midterm Presentation";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>What is NeuroFit?</h2>
                            </header>
                            <p>
                              A pseudo business and prototype for a tDCS product that is meant to question what society and consumers deem "ideal" neurological functions. I want the prototype to be functional, but have the sign-up flow indicate to the user that the first step in neuro-hacking should be in establish a healthy baseline (e.g. sleep, nutrition, work-life balance) — a common, but sometimes overlooked point made by prominent neuro-hacking speakers.
                            </p>

                            <header className="major">
                                <h2>Inspiration</h2>
                            </header>
                            <p>
                              Inspiration for this project stems from the productivity/efficiency lifestyle movements (e.g. Tim Ferris), the online neuro-hacking communities found on community boards like Reddit's [/r/nootropics](https://reddit.com/r/nootropics), lifestyle fitness startups (e.g. Peloton, Soulcycle), and satrical speculative design projects like the <a href="http://tbdcatalog.com/">Imaginary Catalog</a>.
                            </p>

                            <header className="major">
                                <h2>Target Audience</h2>
                            </header>
                            <p>
                              Tech enabled professionals seeking an edge to be more productive, i.e. someone who is perfectly happy to replace all meals with Soylent.
                            </p>

                            <header className="major">
                                <h2>Precedent</h2>
                            </header>
                            <p>
                              There are existing companies that market themselves in a similar, but non-satirical, fashion:
                            </p>

                            <ul>
                              <li><a href="https://www.haloneuro.com/">Halo</a></li>
                              <li><a href="https://www.foc.us/">Foc.us</a></li>
                              <li><a href="https://thebrainstimulator.net">The Brain Simulator</a>/</li>
                            </ul>

                            <header className="major">
                                <h2>Research</h2>
                            </header>

                            <h3>First hand experience</h3>
                            <ul>
                              <li>http://traffic.libsyn.com/futurethinkers/FTP042.mp3</li>
                              <li>https://youtu.be/xzbHtIrb14s</li>
                              <li>https://www.wnycstudios.org/story/9-volt-nirvana/</li>
                              <li>https://clinicaltrials.gov/ct2/show/NCT03190590?term=tdcs&recrs=a&map_cntry=US&map_state=US%3ANY&gndr=Male&age=1&hlth=Y&rank=4</li>
                            </ul>

                            <h3>Coverage</h3>
                            <ul>
                              <li>https://www.wired.com/2014/01/read-zapping-brain/</li>
                              <li>https://www.scientificamerican.com/article/do-diy-brain-booster-devices-work/</li>
                              <li>https://www.eurekalert.org/pub_releases/2016-07/bidm-nwa070816.php]</li>
                              <li>https://vitals.lifehacker.com/the-truth-about-electrical-brain-stimulation-1822192429</li>
                              <li>https://www.ncbi.nlm.nih.gov/pubmed/26280313%20</li>
                              <li>https://www.reddit.com/r/nootropics/wiki/beginners</li>
                            </ul>

                            <h3>DIY Projects</h3>
                            <ul>
                              <li>https://www.reddit.com/r/tDCS/comments/2adacm/collected_knowledge_from_a_new_tdcs_convert/</li>
                              <li>https://www.reddit.com/r/tDCS/comments/qn6s5/diy_tdcs_howto/</li>
                              <li>https://www.reddit.com/r/tDCS/comments/2e7idx/simple_montage_list_with_electrode_placement_and/</li>
                              <li>https://www.diytdcs.com/2017/05/diy-tdcs-youtube-catch-up/</li>
                              <li>https://www.instructables.com/id/Build-a-Human-Enhancement-Device-Basic-tDCS-Suppl/</li>
                              <li>https://www.makeuseof.com/tag/build-tdcs-brain-stimulator/</li>
                            </ul>

                            <header className="major">
                                <h2>Obstacles</h2>
                            </header>
                            <ul>
                              <li>Not being able to build a working (or safe) prototype</li>
                              <li>Project fails to highlight the core ethical question of how do we define "normal" or "ideal" neurological function</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default MidtermPage;
